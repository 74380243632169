import React from "react"
import Gallery from "react-photo-gallery"
import Carousel from "react-images"
import ReactMarkdown from "react-markdown"
import gfm from 'remark-gfm'

const Horizontal = ({ gauche, droite }) => {
  return (
    <div className="columns">
      <div className="column">{gauche}</div>
      <div className="column">{droite}</div>
    </div>
  )
}
const Vertical = ({ haut, bas }) => {
  return [
    <div className="columns">
      <div className="column">{haut}</div>
    </div>,
    <div className="columns">
      <div className="column">{bas}</div>
    </div>,
  ]
}

const Block = ({ contenue, photos, position, type }) => {
  const contenuHtml = <div className="content">  <ReactMarkdown plugins={[gfm]} children={contenue} /> </div>
  const images = () => {
    switch (type) {
      case "Carrousel":
        return (
          <Carousel
            views={photos.map(photo => ({ source: photo.localFile.childImageSharp.gatsbyImageData.images.fallback.src }))}
          />
        )
      case "Galerie":
        return (<div className="pareo-v-parent">
          <div className="pareo-v-child" >
            <Gallery
              photos={photos.map(
                (photo) => {
                  return {
                    key: "" + photo.id,
                    src: photo.localFile.childImageSharp.gatsbyImageData.images.fallback.src,
                    width: photo.localFile.childImageSharp.resize.aspectRatio, key: photo.id,
                    height: 1
                  }
                }
              )}></Gallery>

          </div>)

        </div>)
      default:
        return (<></>)
    }
  }

  const rendu = () => {
    switch (position) {
      case "Haut":
        return <Vertical haut={images()} bas={contenuHtml} />
      case "Bas":
        return <Vertical haut={contenuHtml} bas={images()} />
      case "Gauche":
        return <Horizontal gauche={images()} droite={contenuHtml} />
      case "Droit":
      default:
        return <Horizontal gauche={contenuHtml} droite={images()} />
    }
  }

  return <div className="box container">{rendu()}</div>
}

export default Block
