import React, { Component } from "react"
import { graphql } from "gatsby"

import IndexLayout from '../layouts'
import Block from "../components/Block"

const AproposPage = ({ data }) => {
  return (
    <IndexLayout>
      <section className="hero is-primary">
        <div className="hero-body">
          <div className="container ">
            <h1 className="title has-text-centered">
              {data.strapiAPropos.Titre}
            </h1>
          </div>
        </div>
      </section>
      <section className="section">
        {data.strapiAPropos.Block.map(block => [
          <Block
            key={""+block.id}
            contenue={block.Contenu}
            photos={block.Images}
            position={block.Position}
            type={block.Type}
          ></Block>,
        ])}
      </section>
    </IndexLayout>
  )
}

export default AproposPage


export const pageQuery = graphql`
query APropos {
  strapiAPropos {
    Block {
      Contenu
      Position
      Type
      id
      Images {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData
            resize {
              aspectRatio
            }
          }
        }
        caption
        id
      }
    }
    Titre
  }
}
`